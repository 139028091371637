import axios from 'axios';

//const API_URL = 'https://teste.com';
//const API_URL = 'http://127.0.0.1:5000'
const API_URL = 'https://higienizeaqui.com.br/api';
//const API_URL = 'http://107.155.87.158/api';

/**
 * Faz login e armazena o access_token e refresh_token no localStorage
 */
export const loginUser = async (credentials) => {
    const response = await axios.post(`${API_URL}/login`, credentials);
    if (response.data.access_token) {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);  // Armazena o refresh token
        localStorage.setItem('username', response.data.name);
    }
    return response.data;
};

/**
 * Registra um novo usuário
 */
export const registerUser = async (userData) => {
    const response = await axios.post(`${API_URL}/signup`, userData);
    return response.data;
};

/**
 * Envia e-mail para redefinição de senha
 */
export const sendPasswordResetEmail = async (email) => {
    const response = await axios.post(`${API_URL}/forgot-password`, { email });
    return response.data;
};

/**
 * Verifica o código de verificação enviado para o e-mail
 */
export const verifyUser = async (email, code) => {
    const response = await axios.post(`${API_URL}/verify`, { email, code });
    return response.data;
};

/**
 * Reseta a senha com base no token recebido
 */
export const resetPassword = async (token, newPassword) => {
    const response = await axios.post(`${API_URL}/reset-password`, { token, password: newPassword });
    return response.data;
};

/**
 * Atualiza o access token usando o refresh token armazenado
 */
export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
        throw new Error("Refresh token não encontrado");
    }

    try {
        const response = await axios.post(`${API_URL}/refresh`, {}, {
            headers: {
                'Authorization': `Bearer ${refreshToken}`
            }
        });

        // Atualiza o novo access token no localStorage
        localStorage.setItem('access_token', response.data.access_token);

        return response.data.access_token;
    } catch (error) {
        console.error("Erro ao tentar atualizar o token:", error);
        throw error;
    }
};

/**
 * Pega o access token armazenado no localStorage
 */
const getAuthToken = () => {
    return localStorage.getItem('access_token');
};

/**
 * Retorna o header com o access token para requisições protegidas
 */
const authHeaders = () => {
    const token = getAuthToken();
    if (token) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
};

/**
 * Remove os tokens do localStorage e faz o logout do usuário
 */
export const logoutUser = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
};
