import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import UserDashboard from './pages/UserDashboard';
import EmailVerification from './pages/EmailVerification';
import ResetPassword from './pages/ResetPassword';
import UserSettings from './pages/UserSettings';
import './styles/global.css';

const App = () => (
    <Router>
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/verify" element={<EmailVerification />} /> {/* Nova Rota */}
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/" element={<Login />} />
            <Route path="/user-settings" element={<UserSettings />} />
            <Route path="/dashboard" element={<UserDashboard />} />
        </Routes>
    </Router>
);

ReactDOM.render(<App />, document.getElementById('root'));
