import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputField from './InputField';
import axios from 'axios';
import InputMask from 'react-input-mask';
import styles from './PersonalInfo.module.css';

const PersonalInfo = () => {
    const [initialValues, setInitialValues] = useState({
        name: '',
        email: '',
        phone: '',
        cpf: '',
    });
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('http://localhost:5000/user/profile', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                });
                setInitialValues(response.data);
            } catch (error) {
                console.error('Erro ao buscar dados do usuário:', error);
            }
        };
        fetchUserData();
    }, []);

    const validationSchema = Yup.object({
        name: Yup.string().required('Nome é obrigatório'),
        phone: Yup.string().required('Telefone é obrigatório'),
        cpf: Yup.string()
            .required('CPF é obrigatório')
            .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido'), // Valida o formato do CPF
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {

            const cleanPhone = values.phone.replace(/[()\s-]/g, '');
            const cleanCpf = values.cpf.replace(/[.-]/g, '');

            const response = await axios.put('http://localhost:5000/update-info', {
                ...values,
                phone: cleanPhone,
                cpf: cleanCpf,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });

            setSuccessMessage('Informações atualizadas com sucesso!');
        } catch (error) {
            console.error('Erro ao atualizar dados do usuário:', error);
            alert('Erro ao atualizar os dados.');
        }
        setSubmitting(false);
    };

    return (
        <div className={styles.section}>
            <h3>Dados Pessoais</h3>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, values, handleChange }) => (
                    <Form className={styles.form}>
                        <div className={styles.inputContainer}>
                            <InputField name="name" label="Nome" />
                            <label htmlFor="email">Email</label>
                            <input
                                name="email"
                                type="email"
                                value={values.email}
                                disabled
                                className={styles.inactiveInput}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <label htmlFor="phone">Telefone</label>
                            <InputMask
                                mask="(99) 99999-9999"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                            >
                                {(inputProps) => (
                                    <input
                                        {...inputProps}
                                        type="text"
                                        id="phone"
                                        placeholder="(99) 99999-9999"
                                    />
                                )}
                            </InputMask>
                        </div>

                        {/* Campo CPF */}
                        <div className={styles.inputContainer}>
                            <label htmlFor="cpf">CPF</label>
                            <InputMask
                                mask="999.999.999-99"
                                name="cpf"
                                value={values.cpf}
                                onChange={handleChange}
                            >
                                {(inputProps) => (
                                    <input
                                        {...inputProps}
                                        type="text"
                                        id="cpf"
                                        placeholder="000.000.000-00"
                                    />
                                )}
                            </InputMask>
                        </div>

                        <button
                            type="submit"
                            className={styles.smallSaveButton}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Salvando...' : 'Salvar/Atualizar'}
                        </button>
                        {/* Exibe a mensagem de sucesso se ela existir */}
                        {successMessage && <p className={styles.success}>{successMessage}</p>}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PersonalInfo;
