import React from 'react';
import Navbar from '../components/Navbar';
import styles from './UserSettings.module.css';
import PersonalInfo from '../components/PersonalInfo';
import PaymentMethods from '../components/PaymentMethods';

const UserSettings = () => {
    return (
        <div>
            <Navbar username="Usuário" balance="1500.00" />
            <div className={styles.container}>
                <h2>Configurações do(a) Usuário</h2>
                <PersonalInfo />
                <PaymentMethods />
            </div>
        </div>
    );
};

export default UserSettings;
