import React from 'react';
import { MercadoPago } from '@mercadopago/sdk-react';

const PaymentMethods = ({ packageId }) => {
    const PUBLIC_KEY = 'SUA_PUBLIC_KEY';

    const handlePayment = async () => {

        const response = await fetch('http://localhost:5000/create-preference', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ packageId }),
        });
        const data = await response.json();

        // Iniciar o checkout
        if (data.preferenceId) {
            window.location.href = `https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=${data.preferenceId}`;
        } else {
            console.error('Erro ao criar a preferência de pagamento');
        }
    };

    return (
        <div>
            <button onClick={handlePayment}>Pagar com Mercado Pago</button>
        </div>
    );
};

export default PaymentMethods;
