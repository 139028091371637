import React, { useState, useEffect } from 'react';
import { FaCog, FaPiggyBank } from 'react-icons/fa';
import axios from 'axios';
import styles from './Navbar.module.css';
import { refreshAccessToken } from '../services/authService';

const Navbar = ({ username }) => {
    const [balance, setBalance] = useState(0);

    // Função para buscar o saldo de credits do backend
    const fetchBalance = async () => {
        try {
            //Pega o access_token do localStorage
            const accessToken = localStorage.getItem('access_token');

            if (!accessToken) {
                console.error("Access token não encontrado");
                return;
            }


            const response = await axios.get('http://localhost:5000/user/credits', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,  //envia o access token no cabecalho
                }
            });

            setBalance(response.data.credits);  //att o saldo de credts
        } catch (error) {
            if (error.response && error.response.data.msg === "Token has expired") {
                //se o token expirar tenta fazer o refresh
                try {
                    const newAccessToken = await refreshAccessToken();

                    const response = await axios.get('http://localhost:5000/user/credits', {
                        headers: {
                            'Authorization': `Bearer ${newAccessToken}`
                        }
                    });

                    setBalance(response.data.credits);
                } catch (refreshError) {
                    console.error("Erro ao atualizar o token:", refreshError);
                }
            } else {
                console.error("Erro ao carregar o saldo de créditos:", error);
            }
        }
    };

    //para buscar o saldo de creditos assim que o componente for montado
    useEffect(() => {
        fetchBalance();
    }, []);

    return (
        <div className={styles.navbar}>
            <div className={styles.username}>
                Bem-vindo, {username}
            </div>
            <div className={styles.icons}>
                <FaPiggyBank className={styles.icon} title={`Saldo disponível: ${balance} CPFs`} />
                <FaCog className={styles.icon} title="Configurações" />
            </div>
        </div>
    );
};

export default Navbar;
