import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPiggyBank, faDownload } from '@fortawesome/free-solid-svg-icons';
import styles from './UserDashboard.module.css';
import axios from 'axios';
import { Table, Spin, Layout, Menu, Breadcrumb, Badge, Modal } from 'antd';
import 'antd/dist/reset.css';
import PersonalInfo from "../components/PersonalInfo";
import { faCoins } from '@fortawesome/free-solid-svg-icons';


import {
    PieChartOutlined,
    SearchOutlined,
    HistoryOutlined,
    BellOutlined,
    DollarOutlined,
    UserOutlined,
    MessageOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import ResetPassword from "./ResetPassword";

const { Header, Content, Footer, Sider } = Layout;


const breadcrumbMap = {
    dashboard: ['Dashboard'],
    'buy-credits': ['Comprar Créditos'],
    support: ['Suporte'],
    profile: ['Configurações', 'Perfil'],
    security: ['Configurações', 'Segurança'],
    notify: ['Configurações', 'Notificações'],
    transactions: ['Histórico de Compras'],
};


const UserDashboard = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [verificationMessage, setVerificationMessage] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [credits, setCredits] = useState(0);  //Saldo de créditos
    const [transactions, setTransactions] = useState([]);  //movimentações financeiras
    const [queryHistory, setQueryHistory] = useState([]);  //histórico de consultas
    const [selectedPackage, setSelectedPackage] = useState(null); //pacote selecionado para compra (falta fazer)
    const [csvUrl, setCsvUrl] = useState(null); //url para download do arquivo CSV processado
    const [processing, setProcessing] = useState(false);
    const [username, setUsername] = useState(localStorage.getItem('username') || 'Usuário');
    const [currentPage, setCurrentPage] = useState('dashboard'); // Define a página inicial como 'dashboard'
    const [fadeClass, setFadeClass] = useState('content-fade');

    const navigate = useNavigate();

    useEffect(() => {
        const isVerified = localStorage.getItem('is_verified');
        if (isVerified === 'false') {
            setVerificationMessage('Conta não verificada. Verifique seu e-mail.');
        }

        // Verificar se há um processo em andamento no localStorage
        const isProcessing = localStorage.getItem('processing') === 'true';
        if (isProcessing) {
            setProcessing(true);
        }

        //carregar o saldo de créditos e movimentações e histórico de consultas
        const fetchCreditsAndTransactions = async () => {
            try {
                const accessToken = localStorage.getItem('access_token');

                if (!accessToken) {
                    console.error("Access token não encontrado");
                    return;
                }

                // Fazendo a requisição para obter os créditos e transações
                const creditsResponse = await axios.get('http://localhost:5000/user/credits', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                setCredits(creditsResponse.data.credits);
                setTransactions(creditsResponse.data.transactions || []);

                // Fazendo a requisição para obter o histórico de consultas
                const historyResponse = await axios.get('http://localhost:5000/user/query-history', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                setQueryHistory(historyResponse.data || []);

            } catch (error) {
                console.error('Erro ao carregar saldo de créditos, movimentações ou histórico de consultas:', error);
            }
        };

        fetchCreditsAndTransactions();
    }, []);

    // Segundo useEffect: carregar e salvar a página atual no localStorage
    useEffect(() => {
        const savedPage = localStorage.getItem('currentPage') || 'dashboard';
        setCurrentPage(savedPage);
    }, []);

    useEffect(() => {
        // Garante que a página inicial seja sempre 'dashboard' ao carregar
        setCurrentPage('dashboard');
    }, []);

    const fetchTransactions = async () => {
        try {
            const accessToken = localStorage.getItem('access_token');
            if (!accessToken) {
                console.error("Access token não encontrado");
                return;
            }

            const response = await axios.get('http://localhost:5000/user/transactions', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            setTransactions(response.data || []);

        } catch (error) {
            console.error('Erro ao carregar o histórico de transações:', error);
        }
    };

    useEffect(() => {
        if (currentPage === 'transactions') {
            fetchTransactions();
        }
    }, [currentPage]);

    const transactionColumns = [
        {
            title: 'Data',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Quantidade de Créditos',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Valor Total',
            dataIndex: 'total_price',
            key: 'total_price',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];



    // Função para contar os CPFs no arquivo CSV
    const countCpfsInFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const content = event.target.result;
                const lines = content.split('\n');
                resolve(lines.length - 1); // Desconsidera o cabeçalho
            };
            reader.onerror = () => reject('Erro ao ler o arquivo.');
            reader.readAsText(file);
        });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setCsvUrl(null); //limpa a URL do CSV ao selecionar um novo arquivo
    };

    const handleUpload = async () => {
        if (!file) {
            setMessage('Por favor, selecione um arquivo.');
            return;
        }

        if (credits < 1) {
            setMessage('Você não tem créditos suficientes para realizar essa consulta. Compre mais créditos.');
            return;
        }

        try {
            setMessage('Contando CPFs...');
            const numCpfs = await countCpfsInFile(file);

            // Atualiza a mensagem para "Processando" com o spinner
            setMessage('Processando arquivo, por favor aguarde...');
            setProcessing(true);
            localStorage.setItem('processing', 'true'); // Marca que o processamento está em andamento

            // Adiciona uma entrada "fake" à tabela com o spinner
            const tempHistory = {
                date: new Date().toLocaleString(),
                sent_cpfs: numCpfs,
                returned_cpfs: (
                    <span style={{ color: 'green', display: 'flex', alignItems: 'center' }}>
                    Em Processamento <Spin style={{ marginLeft: '8px', color: 'green' }} />
                </span>
                ),
                remaining_credits: credits - numCpfs,  // Deduzir o número de CPFs dos créditos restantes
                download: null  // Download vazio até o backend retornar o arquivo
            };
            setQueryHistory(prevHistory => [tempHistory, ...prevHistory]);  // Atualiza a tabela com a entrada fake

            // Faz o upload do arquivo para o backend
            const formData = new FormData();
            formData.append('file', file);

            const accessToken = localStorage.getItem('access_token');
            if (!accessToken) {
                console.error("Access token não encontrado");
                setMessage('Erro de autenticação. Faça login novamente.');
                return;
            }

            // Faz a requisição para o backend
            const response = await axios.post('http://localhost:5000/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${accessToken}`,
                },
                responseType: 'json',  // Espera uma resposta JSON com os resultados do processamento
            });

            // Extrai os dados retornados do backend
            const { returned_cpfs, remaining_credits, download_url } = response.data;

            // Atualiza a entrada "fake" com os dados reais do processamento
            setQueryHistory(prevHistory => prevHistory.map((entry, index) =>
                index === 0  // Atualiza o primeiro registro "fake"
                    ? { ...entry, returned_cpfs, remaining_credits, download: download_url }
                    : entry
            ));

            setMessage('Consulta realizada com sucesso!');
            setProcessing(false);
            localStorage.setItem('processing', 'false');  // Marca que o processamento foi concluído

        } catch (error) {
            setMessage('Erro ao realizar a consulta.');
            setProcessing(false);
            localStorage.setItem('processing', 'false');
        }
    };

    //define colunas da tabela de histórico de consultas do component ant
    const columns = [
        {
            title: 'Data',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'CPFs Enviados',
            dataIndex: 'sent_cpfs',
            key: 'sent_cpfs',
        },
        {
            title: 'CPFs Retornados',
            dataIndex: 'returned_cpfs',
            key: 'returned_cpfs',
        },
        {
            title: 'Créditos Restantes',
            dataIndex: 'remaining_credits',
            key: 'remaining_credits',
        },
        {
            title: 'Download',
            key: 'download',
            render: (text, record) => (
                record.download_url ? (
                    <a href={record.download_url} download="resultado_cpfs.csv">
                        <FontAwesomeIcon icon={faDownload} />
                    </a>
                ) : null
            ),
        }
    ];

    // Lógica para redirecionar para pagamento após selecionar um pacote
    const handleBuyCredits = async (packageId) => {
        try {
            const accessToken = localStorage.getItem('access_token');

            if (!accessToken) {
                console.error("Access token não encontrado");
                return;
            }

            // Fazendo a requisição para criar a preferência de pagamento
            const response = await axios.post('http://localhost:5000/create-preference',
                { packageId },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            const { preferenceId } = response.data;
            //const { preferenceId, init_point } = response.data;

            if (preferenceId) {
                // Redirecionar para o Mercado Pago usando o preferenceId
                //window.location.href = `https://www.mercadopago.com.br/checkout/v1/redirect?preference-id=${preferenceId}`;
                // Abrir o link do Mercado Pago em uma nova aba
                window.open(`https://www.mercadopago.com.br/checkout/v1/redirect?preference-id=${preferenceId}`, '_blank');
            }

        } catch (error) {
            console.error('Erro ao criar preferência de pagamento:', error);
        }
    };


    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    //Redirecionar para as configurações
    const goToSettings = () => {
        navigate('/user-settings');
    };

    const items = [
        {
            key: 'dashboard',
            icon: <PieChartOutlined />,
            label: 'Dashboard',
        },

        {
            key: 'buy-credits',
            icon: <DollarOutlined />,
            label: 'Comprar Créditos',
        },
        {
            key: 'transactions',
            icon: <HistoryOutlined />,
            label: 'Minhas Compras',
        },
        {
            key: 'sub1',
            icon: <UserOutlined />,
            label: 'Configurações',
            children: [
                { key: 'profile', label: 'Perfil' },
                { key: 'security', label: 'Segurança' },
                {
                    key: 'notify',
                    icon: <Badge count={5}><BellOutlined /></Badge>, // Mostra o número de notificações
                    label: 'Notificações' },
            ],
        },
        {
            key: 'support',
            icon: <MessageOutlined />,
            label: 'Suporte',
        },
        {
            key: 'logout',
            icon: <LogoutOutlined />,
            label: 'Sair',
        },
    ];

    // Função para abrir o modal de confirmação
    const showLogoutConfirm = () => {
        Modal.confirm({
            title: 'Deseja sair?',
            content: 'Você tem certeza que deseja encerrar a sessão?',
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Não',
            onOk() {
                handleLogout();
            },
        });
    };

// Função de logout
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleLogout = () => {
        // Limpar dados de autenticação, se necessário
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');

        // Redirecionar para a tela de login
        navigate('/login');
    };

    const handleMenuClick = (e) => {
        if (e.key === 'logout') {
            showLogoutConfirm(); // Mostra o modal ao clicar em Sair
        } else {
            setCurrentPage(e.key); // Atualiza a página atual
        }
    };


    return (
        <Layout style={{ minHeight: '100vh' }}>
            {/* Menu lateral */}
            <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
                <div className="logo" />
                <Menu theme="dark"
                      selectedKeys={[currentPage]} // Garante que o item correto esteja destacado
                      defaultSelectedKeys={['dashboard']}
                      mode="inline"
                      items={items}
                      onClick={handleMenuClick} // Função que lida com o clique do menu
                />
            </Sider>

            {/* Conteúdo principal */}
            <Layout>
                <Content style={{margin: '0 16px'}}>

                    <div className={styles.breadcrumbContainer}>
                        <Breadcrumb style={{margin: '16px 0', flexGrow: 1}}>
                            <Breadcrumb.Item>Usuário</Breadcrumb.Item>
                            {breadcrumbMap[currentPage]?.map((item, index) => (
                                <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                            ))}
                        </Breadcrumb>

                        {/* Contêiner do ícone e dos créditos */}
                        <div className={styles.creditsContainer}>
                            <FontAwesomeIcon icon={faCoins} className={styles.walletIcon} />
                            <span className={styles.creditsText}>Seus Créditos: {credits}</span>
                        </div>
                    </div>

                    {/* Renderiza o conteúdo da página com base no currentPage */}
                    <div style={{padding: 24, minHeight: 360, background: '#fff'}}>
                        {currentPage === 'dashboard' && (
                            <>
                                {/* Upload de arquivos */}
                                <div className={styles.uploadSection}>
                                    <h3>Envie um arquivo para Consulta</h3>
                                    <ul className={styles.instructionsList}>
                                        <li>O arquivo deve estar no formato <strong>.csv</strong> e conter no
                                            máximo <strong>10.000 linhas</strong>.
                                        </li>
                                        <li>O tempo de processamento pode variar de acordo com o tamanho do arquivo.
                                        </li>
                                        <li>Ao final do processamento, apenas o último arquivo ficará disponível na
                                            sessão.
                                        </li>
                                    </ul>

                                    <div className={styles.uploadContainer}>
                                        <input type="file" accept=".csv" onChange={handleFileChange}
                                               className={styles.fileInput}/>
                                        <button onClick={handleUpload} className={styles.uploadButton}>Enviar Arquivo
                                        </button>
                                    </div>


                                    {message && <p className={styles.message}>{message}</p>}
                                </div>

                                {/* Histórico de consultas */}
                                <div className={styles.historySection}>
                                    <h3>Histórico de Consultas</h3>
                                    <Table columns={columns} dataSource={queryHistory} pagination={{pageSize: 5}}/>
                                </div>
                            </>
                        )}

                        {currentPage === 'buy-credits' && (
                            <div className={styles.buyCreditsContainer}>
                                <h2 className={styles.title}>Comprar Créditos de Consulta</h2>
                                <p className={styles.subtitle}>Escolha um pacote de créditos para suas consultas:</p>

                                <div className={styles.packagesContainer}>
                                    {/* Pacote 1 */}
                                    <div className={styles.packageCard}>
                                        <h3>100 CPFs</h3>
                                        <p>R$ 10,00</p>
                                        <p className={styles.packageDescription}>Ideal para quem precisa de poucas
                                            consultas</p>
                                        <button className={styles.buyButton} onClick={() => handleBuyCredits(100)}>
                                            Comprar Agora
                                        </button>
                                    </div>

                                    {/* Pacote 2 */}
                                    <div className={styles.packageCard}>
                                        <h3>5000 CPFs</h3>
                                        <p>R$ 50,00</p>
                                        <p className={styles.packageDescription}>Pacote popular para consultas
                                            frequentes</p>
                                        <button className={styles.buyButton} onClick={() => handleBuyCredits(5000)}>
                                            Comprar Agora
                                        </button>
                                    </div>

                                    {/* Pacote 3 */}
                                    <div className={styles.packageCard}>
                                        <h3>10000 CPFs</h3>
                                        <p>R$ 90,00</p>
                                        <p className={styles.packageDescription}>Economize mais com este pacote
                                            grande</p>
                                        <button className={styles.buyButton} onClick={() => handleBuyCredits(10000)}>
                                            Comprar Agora
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentPage === 'transactions' && (
                            <div>
                                <h3>Histórico de Transações de Compras</h3>
                                <Table columns={transactionColumns} dataSource={transactions} pagination={{ pageSize: 5 }} />
                            </div>
                        )}


                        {currentPage === 'profile' && (
                            <div>
                                <PersonalInfo/>
                            </div>
                        )}

                        {currentPage === 'security' && (
                            <div>
                                <h3>Configurações de Segurança</h3>
                                {/* Conteúdo de Segurança */}
                                <div>
                                    <ResetPassword/>
                                </div>
                            </div>
                        )}

                        {currentPage === 'notify' && (
                            <div>
                                <h3>Notificações</h3>
                                {/* Conteúdo de Notificações */}
                                <p>Você tem 5 novas notificações.</p>
                            </div>
                        )}

                        {currentPage === 'support' && (
                            <div>
                                <h3>Suporte</h3>
                                {/* Conteúdo de Suporte */}
                                <p>Aqui você pode entrar em contato com o suporte.</p>
                            </div>
                        )}
                    </div>
                </Content>

                <Footer style={{textAlign: 'center'}}>
                    Design ©{new Date().getFullYear()} Created by OliveiraMonica
                </Footer>
            </Layout>
        </Layout>
    );
};

export default UserDashboard;
