import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputField from '../components/InputField';
import Button from '../components/Button';
import { resetPassword } from '../services/authService';
import styles from './ResetPassword.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ResetPassword = () => {
    const [message, setMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);  //estado para controlar a desabilitação do botão
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);  //controla a visibilidade da confirmação de senha
    const { token } = useParams(); // Pega o token da URL
    const navigate = useNavigate();

    const initialValues = {
        password: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Senha é obrigatória')
            .min(6, 'A senha deve ter no mínimo 6 caracteres'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'As senhas devem coincidir')
            .required('Confirmação de senha é obrigatória'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsDisabled(true);  // Desabilita o botão de vez
        try {
            await resetPassword(token, values.password);
            setMessage('Sua senha foi redefinida com sucesso! Redirecionando para o login...');
            setTimeout(() => {
                navigate('/login'); //redireciona para o login apos 5 seg
            }, 5000);
        } catch (error) {
            setMessage('Erro ao redefinir a senha. Tente novamente.');
            setIsDisabled(false);  //habil o botão novamente em caso de erro
        }
        setSubmitting(false);
    };


    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Redefinir Senha</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className={styles.passwordContainer}>
                            <InputField
                                name="password"
                                label="Nova Senha"
                                type={showPassword ? 'text' : 'password'}
                            />
                            <span className={styles.togglePassword} onClick={togglePasswordVisibility}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </span>
                        </div>

                        <div className={styles.passwordContainer}>
                            <InputField
                                name="confirmPassword"
                                label="Confirmar Nova Senha"
                                type={showConfirmPassword ? 'text' : 'password'}
                            />
                            <span className={styles.togglePassword} onClick={toggleConfirmPasswordVisibility}>
                                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                            </span>
                        </div>

                        <Button
                            type="submit"
                            label={isDisabled ? 'Redefinindo...' : 'Redefinir Senha'}
                            disabled={isDisabled || isSubmitting}  //o botão ficará desabilitado até o redrect
                        />
                    </Form>
                )}
            </Formik>

            {message && (
                <p className={message === 'Sua senha foi redefinida com sucesso! Redirecionando para o login...' ? styles.success : styles.error}>
                    {message}
                </p>
            )}
        </div>
    );
};

export default ResetPassword;
