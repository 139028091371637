import React from 'react';
import { ErrorMessage, Field } from 'formik';
import styles from './InputField.module.css';

const InputField = ({ name, label, type = 'text' }) => (
    <div className={styles.inputContainer}>
        <label htmlFor={name}>{label}</label>
        <Field id={name} name={name} type={type} className={styles.input} />
        <ErrorMessage name={name} component="div" className={styles.error} />
    </div>
);

export default InputField;
