import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputField from '../components/InputField';
import Button from '../components/Button';
import { sendPasswordResetEmail } from '../services/authService';
import { Link, useNavigate } from 'react-router-dom';
import styles from './ForgotPassword.module.css';

const ForgotPassword = () => {
    const [message, setMessage] = useState('');
    const [emailNotRegistered, setEmailNotRegistered] = useState(false);
    const navigate = useNavigate();

    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Email inválido').required('Email é obrigatório'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await sendPasswordResetEmail(values.email);
            setMessage('Um link de recuperação foi enviado para o seu e-mail.');
            setEmailNotRegistered(false);
        } catch (error) {
            if (error.response && error.response.status === 404) {

                setMessage('E-mail não cadastrado.');
                setEmailNotRegistered(true);
            } else {
                setMessage('Erro ao enviar o e-mail de recuperação. Tente novamente.');
            }
        }
        setSubmitting(false);
    };

    const handleSignupRedirect = () => {
        navigate('/signup');
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Recuperar Senha</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <InputField name="email" label="Email" type="email" />
                        <Button type="submit" label="Enviar" disabled={isSubmitting} />
                    </Form>
                )}
            </Formik>
            {message && (
                <p className={message === 'E-mail não cadastrado.' ? styles.error : styles.success}>{message}</p>
            )}

            {emailNotRegistered && (
                <Link to="/signup" className={styles.signupLink}>Cadastre sua conta aqui</Link>
            )}
        </div>
    );
};

export default ForgotPassword;
