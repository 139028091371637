import React, { useState } from 'react';
import { verifyUser } from '../services/authService';
import InputField from '../components/InputField';
import Button from '../components/Button';
import styles from './EmailVerification.module.css';

const EmailVerification = () => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await verifyUser(email, code);
            setSuccessMessage('Conta verificada com sucesso!');
            setErrorMessage('');
        } catch (error) {
            setErrorMessage('Código de verificação inválido ou expirado.');
            setSuccessMessage('');
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Verificação de E-mail</h2>
            <form onSubmit={handleSubmit}>
                <InputField
                    name="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <InputField
                    name="code"
                    label="Código de Verificação"
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
                {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                {successMessage && <div className={styles.success}>{successMessage}</div>}
                <Button type="submit" label="Verificar Conta" />
            </form>
        </div>
    );
};

export default EmailVerification;
