import React from 'react';
import styles from './Button.module.css';

const Button = ({ type = 'button', label, onClick, disabled = false }) => {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={styles.button}
        >
            {label}
        </button>
    );
};

export default Button;
