import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputField from '../components/InputField';
import Button from '../components/Button';
import { loginUser } from '../services/authService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import styles from './Login.module.css';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Email inválido').required('Email é obrigatório'),
        password: Yup.string().required('Senha é obrigatória'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await loginUser(values); //variável `response` é atribuída
            const { access_token, refresh_token, is_verified } = response; //use a variável `response`

            // Armazene os tokens em localStorage ou cookies
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('is_verified', is_verified);


            setErrorMessage('');
            navigate('/dashboard');

        } catch (error) {
            console.error('Erro no login:', error);
            if (error.response) {
                if (error.response.status === 404) {
                    setErrorMessage('E-mail não cadastrado.');
                } else if (error.response.status === 401) {
                    setErrorMessage('Credenciais inválidas');
                } else {
                    setErrorMessage('Falha no login, tente novamente.');
                }
            } else {
                setErrorMessage('Falha no login, verifique suas credenciais.');
            }
        }
        setSubmitting(false);
    };


    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Login</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors }) => (
                    <Form>
                        <InputField name="email" label="Email" type="email"/>
                        <div className={styles.passwordContainer}>
                            <InputField
                                name="password"
                                label="Senha"
                                type={showPassword ? 'text' : 'password'}
                            />
                            <span
                                className={styles.togglePassword}
                                onClick={togglePasswordVisibility}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                            </span>
                        </div>

                        {errors.apiError && <div className={styles.error}>{errors.apiError}</div>}

                        <Button type="submit" label="Login" disabled={isSubmitting}/>
                    </Form>
                )}
            </Formik>

            {errorMessage && <p className={styles.error}>{errorMessage}</p>}

            <div className={styles.linkContainer}>
                <Link to="/forgot-password" className={styles.link}>
                    Esqueceu sua senha? Recuperar
                </Link>
                <Link to="/signup" className={styles.link}>
                    Não tem conta? Cadastre-se
                </Link>
            </div>
        </div>
    );
};

export default Login;
